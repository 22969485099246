<template>
  <div class="shortcut-skeleton">
    <div class="image"></div>
    <div class="title"></div>
  </div>
</template>

<script>
export default {
  name: 'shortcutSkeleton'
}
</script>

<style lang="scss" scoped>
.shortcut-skeleton {
  width: 100%;
  padding: 0 10px;

  .image {
    height: 40px;
    width: 40px;
    margin: auto;
    border-radius: 50%;
    background-color: var(--bg);
  }
  .title {
    height: calc(var(--l-h));
    margin-top: 8px;
    border-radius: var(--border-radius);
    background-color: var(--bg);
  }
}
</style>
