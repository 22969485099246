<template>
  <div class="chart-area-skeleton">
    <div class="chart-box">
<!--      <custom-icon icon="HOME" height="100%" wid />-->
<!--      <img src="../../assets/images/CHART_AREA.png" >-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 1502 504.85">
&lt;!&ndash;        <defs>
          <style>.cls-1 {
            fill: #fff;
          }</style>
        </defs>&ndash;&gt;
        <path class="cls-1"
              d="M-384.12-81l1502,.86V-243.51c-152.22,4.49-161,106.34-236.51,106.34S758.44-348.12,654.83-351.62s-155-179.92-278-183.43-180,46-261,45.44S-28.29-585-131.9-585s-177.81,47.2-252.22,45.44Z"
              transform="translate(384.12 584.98)"/>
      </svg>-->
      <!--      <div style="&#45;&#45;start: 0.6; &#45;&#45;end: 0.4;"> 80% </div>
            <div style="&#45;&#45;start: 0.4; &#45;&#45;end: 0.5;"> 50% </div>
            <div style="&#45;&#45;start: 0.5; &#45;&#45;end: 0.3;"> 60% </div>
            <div style="&#45;&#45;start: 0.3; &#45;&#45;end: 0.8;"> 30% </div>
            <div style="&#45;&#45;start: 0.8; &#45;&#45;end: 0.3;"> 100% </div>-->
    </div>
  </div>
</template>

<script>
import CustomIcon from '@/components/customIcon/customIcon.vue'

export default {
  name: 'chartAeraSkeleton',
  components: {CustomIcon}
}
</script>

<style lang="scss" scoped>
.chart-area-skeleton {
  height: 100%;

  .chart-box {
    display: flex;
    height: 100%;
    background-image: url("../../assets/images/CHART_AREA.png");
    background-size: 100% 100%;
  }
}
</style>
