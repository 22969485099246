<template>
  <div class="product-barcode-skeleton">
    <div class="inputs">
      <template v-for="key in 4">
        <div :key="`barcode-input-box-${key}`" class="input-box w-1/2 md:w-1/4 p-1"></div>
      </template>
    </div>
    <div class="barcode-box">
      <div class="barcode-card">
        <div class="image-box">
          <div class="image"></div>
        </div>
        <div class="product-info">
          <div class="product-barcode"></div>
          <div class="product-title"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'productBarcodeSkeleton'
}
</script>

<style lang="scss" scoped>
.product-barcode-skeleton {
  display: flex;
  flex-direction: column;
  height: 100%;

  .inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 10px;
    margin-bottom: 10px;
    border: 1px solid var(--bg);
    border-radius: 0.5rem;

    .input-box {
      height: 35px;
      border-radius: var(--border-radius);
      background-color: var(--bg);
    }
  }

  .barcode-box {
    flex: 1;
    border: 1px solid var(--bg);
    border-radius: 0.5rem;

    .barcode-card {
      display: flex;
      height: 49.5mm;
      width: 105mm;
      margin: 15px auto;
      padding: 1mm;
      border: 1px solid var(--bg);

      .image-box {
        width: 40mm;
        height: 100%;
        padding-right: 1mm;
        border-right: 3px dashed #cdcdcd;

        .image {
          height: 100%;
          width: 100%;
          border-radius: var(--border-radius);
          background-color: var(--bg);
        }
      }

      .product-info {
        flex: 1;
        margin-left: 5px;

        .product-barcode {
          height: 112px;
          border-radius: var(--border-radius);
          background-color: var(--bg);
        }

        .product-title {
          margin-top: 15px;
          height: calc(var(--l-h) + 10px);
          border-radius: var(--border-radius);
          background-color: var(--bg);
        }
      }
    }
  }
}
</style>
