<template>
  <div class="chat-card-skeleton">
    <div class="card-icon"></div>
    <div class="card-body">
    </div>
    <div class="date-box">
      <div class="card-date"></div>
      <div class="card-date"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chatCardSkeleton'
}
</script>

<style lang="scss" scoped>
.chat-card-skeleton {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  padding: 10px 0;

  .card-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: var(--bg);
  }

  .card-body {
    width: calc(100% - 110px);
    max-width: 500px;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
    background-color: var(--bg);
    min-height: 90px;
    margin: 0 10px;

    .card-label {
      display: flex;
      justify-content: space-between;
      flex: 1;

      .os-name {
        height: calc(var(--l-h) + 5px);
        width: 30%;
        border-radius: var(--border-radius);
        background-color: var(--bg);
      }

      .last-used {
        height: calc(var(--l-h));
        width: 20%;
        border-radius: var(--border-radius);
        background-color: var(--bg);
      }
    }

  }


  .date-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 70px;

    .card-date {
      width: 60px;
      min-height: 20px;
      margin-top: 5px;
      border-radius: var(--border-radius);
      background-color: var(--bg);

      &:nth-child(2) {
        width: 80px;
      }
    }
  }
}
</style>
