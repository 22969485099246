<template>
  <div class="active-session-card-skeleton">
    <div class="card-icon"></div>
    <div class="card-body">
      <div class="card-label">
        <div class="os-name"></div>
        <div class="last-used"></div>
      </div>
      <div class="card-text"></div>
      <div class="card-text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'activeSessionCardSkeleton'
}
</script>

<style lang="scss" scoped>
.active-session-card-skeleton {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;

  .card-icon {
    height: calc(var(--l-h) + 10px);
    width: calc(var(--l-h) + 10px);
    margin-right: 15px;
    border-radius: 50%;
    background-color: var(--bg);
  }

  .card-body {
    flex: 1;

    .card-label {
      display: flex;
      justify-content: space-between;
      flex: 1;

      .os-name {
        height: calc(var(--l-h) + 5px);
        width: 30%;
        border-radius: var(--border-radius);
        background-color: var(--bg);
      }

      .last-used {
        height: calc(var(--l-h));
        width: 20%;
        border-radius: var(--border-radius);
        background-color: var(--bg);
      }
    }

    .card-text {
      height: calc(var(--l-h));
      width: 20%;
      margin-top: 5px;
      border-radius: var(--border-radius);
      background-color: var(--bg);
    }
  }
}
</style>
