<template>
  <div class="card-tree-skeleton">
    <div class="card-header">
      <tree-skeleton :lines="1" />
    </div>
    <div class="card-body">
      <tree-skeleton :lines="3" />
    </div>
  </div>
</template>

<script>

import TreeSkeleton from '@/components/customSkeleton/treeSkeleton.vue'

export default {
  name: 'cardTreeSkeleton',
  components: {TreeSkeleton}
}
</script>

<style lang="scss" scoped>
.card-tree-skeleton {
  border-radius: var(--border-radius);
  background-color: var(--c-bg);
  border: 1px solid var(--bg);

  .card-header {
    padding: 10px;
    border-bottom: 1px solid var(--bg);
  }

  .card-body {
    padding: 10px;
  }
}
</style>
