<template>
  <transition name="fade" :delay="200">
    <div class="accordion-list-item-children">
      <transition name="fade">
        <div class="accordion-list-item-parent-box flex"
             v-if="!parentData.hasOwnProperty('show') || parentData.show">
          <button class="category-selector" @click="selectData"/>
          <button class="accordion-list-item-parent"
                  @click="handleSelectParent">

            <div class="collapse-icon" v-if="children.length">
              <font-awesome-icon :icon="show ? 'angle-down' : 'angle-left'"/>
            </div>

            <span :class="[children.length === 0 ? 'ml-1' : '']">{{ parentData.name }}</span>

            <div class="lock-icon" v-if="parentData.lock">
              <font-awesome-icon icon="lock"/>
            </div>
          </button>

          <vs-divider></vs-divider>

          <!-- insert child dialog -->
          <button class="actions-button useral-gradient-color-success insert-new-child"
                  @click="insertChildDialogStatus = true"
                  v-if="editable">
            <font-awesome-icon icon="plus"/>
          </button>

          <vs-prompt
            color="success"
            :accept-text="$t(`products.insert.accordionList.buttons.accept`)"
            :cancel-text="$t(`products.insert.accordionList.buttons.cancel`)"
            @cancel="cancelAction"
            @accept="handleAction"
            @close="cancelAction"
            :title="$t(`products.insert.accordionList.${type}.insert.title`)"
            class="accordion-list-insert-item-dialog"
            :active.sync="insertChildDialogStatus">
            <accordion-list-insert-item :type="type" v-model="item"/>
          </vs-prompt>

          <!-- /insert child dialog -->


          <!-- edit parent dialog -->
          <button class="actions-button useral-gradient-color-warning edit-parent"
                  @click="setItemToEdit"
                  v-if="editable">
            <font-awesome-icon icon="pen"/>
          </button>

          <vs-prompt
            color="success"
            :accept-text="$t(`products.insert.accordionList.buttons.accept`)"
            :cancel-text="$t(`products.insert.accordionList.buttons.cancel`)"
            @cancel="cancelAction"
            @accept="handleAction"
            @close="cancelAction"
            :title="$t(`products.insert.accordionList.${type}.edit.title`)"
            class="accordion-list-insert-item-dialog"
            :active.sync="editParentDialogStatus">
            <accordion-list-insert-item :type="type" v-model="item"/>
          </vs-prompt>
          <!-- /edit parent dialog -->

          <!-- delete parent dialog -->
          <button
            :class="['actions-button', 'useral-gradient-color-danger', 'delete-parent', parentData.lock === 1 ? 'disabled' : '']"
            :disabled="parentData.lock === 1"
            @click="deleteItemAction"
            v-if="editable">
            <font-awesome-icon icon="trash"/>
          </button>

          <vs-prompt
            color="success"
            :accept-text="$t(`products.insert.accordionList.buttons.accept`)"
            :cancel-text="$t(`products.insert.accordionList.buttons.cancel`)"
            @cancel="cancelAction"
            @accept="handleAction"
            @close="cancelAction"
            :title="$t(`products.insert.accordionList.${type}.delete.title`)"
            class="accordion-list-insert-item-dialog"
            :active.sync="deleteParentDialogStatus">
            <p>{{ $t(`products.insert.accordionList.${type}.delete.message`) }}</p>
          </vs-prompt>
          <!-- /delete parent dialog -->
        </div>
      </transition>
      <accordion-list-content v-show="show"
                              :parent-data="getParentData(child)"
                              :children="child.childrens || []"
                              @item:changed="$emit('item:changed')"
                              @item:clicked="$emit('item:clicked')"
                              v-model="selectedData"
                              :type="type"
                              :editable="editable"
                              v-for="(child, child_index) in children"
                              :key="child_index"/>
    </div>
  </transition>
</template>

<script>
import AccordionListInsertItem from '@/components/accordionList/AccordionListInsertItem'
import {
  createBrand,
  deleteBrand,
  updateBrand
} from '@/http/requests/products'
import {
  createCategory,
  deleteCategory,
  updateCategory
} from '@/http/requests/categories'

export default {
  name: 'AccordionListContent',
  components: {
    AccordionListInsertItem
  },
  props: {
    parentData: {
      type: Object,
      default: () => { return {} }
    },
    children: {},
    type: {
      type: String,
      default: '',
      validator (value) {
        return ['category', 'brand'].includes(value)
      }
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      show: false,
      status: 'insert',
      selectedData: {},
      deleteParentDialogStatus: false,
      editParentDialogStatus: false,
      insertChildDialogStatus: false,
      item: {
        id: 'new',
        name: '',
        attributes: [],
        parent_id: this.parentData.id
      }
    }
  },
  methods: {
    getParentData (data) {
      const parent = {}
      Object.keys(data).forEach((key) => {
        if (key !== 'childrens') parent[key] = data[key]
      })
      return parent
    },
    handleSelectParent () {
      if (this.children.length) this.show = !this.show
      else {
        this.selectedData = this.parentData
        this.$emit('item:clicked')
      }
    },
    selectData () {
      this.selectedData = this.parentData
      this.$emit('item:clicked')
    },
    handleAction () {
      switch (this.status) {
      case 'insert':
        if (this.type === 'category') {
          createCategory(this.item).then(() => {
            this.item = {
              id: 'new',
              name: '',
              parent_id: this.parentData.id,
              attributes: []
            }
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.title'),
              text: this.$t(`products.insert.accordionList.alert.${this.type}Created`),
              time: this.$u.getReadingTime(this.$t(`products.insert.accordionList.alert.${this.type}Created`)),
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            })
            this.$emit('item:changed')
          }).catch(() => {
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.errorTitle'),
              text: this.$t('products.insert.accordionList.alert.error'),
              time: this.$u.getReadingTime(this.$t('products.insert.accordionList.alert.error')),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          })
        } else if (this.type === 'brand') {
          createBrand(this.item).then(() => {
            this.item = {
              id: 'new',
              name: '',
              parent_id: this.parentData.id,
              attributes: []
            }
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.title'),
              text: this.$t(`products.insert.accordionList.alert.${this.type}Created`),
              time: this.$u.getReadingTime(this.$t(`products.insert.accordionList.alert.${this.type}Created`)),
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            })
            this.$emit('item:changed')
          }).catch(() => {
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.errorTitle'),
              text: this.$t('products.insert.accordionList.alert.error'),
              time: this.$u.getReadingTime(this.$t('products.insert.accordionList.alert.error')),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          })
        }
        break

      case 'edit':
        if (this.type === 'category') {
          updateCategory(this.item).then(() => {
            this.item = {
              id: 'new',
              name: '',
              parent_id: this.parentData.id,
              attributes: []
            }
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.title'),
              text: this.$t(`products.insert.accordionList.alert.${this.type}Updated`),
              time: this.$u.getReadingTime(this.$t(`products.insert.accordionList.alert.${this.type}Updated`)),
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            })
            this.$emit('item:changed')
          }).catch(() => {
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.errorTitle'),
              text: this.$t('products.insert.accordionList.alert.error'),
              time: this.$u.getReadingTime(this.$t('products.insert.accordionList.alert.error')),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          })
        } else if (this.type === 'brand') {
          updateBrand(this.item).then(() => {
            this.item = {
              id: 'new',
              name: '',
              parent_id: this.parentData.id,
              attributes: []
            }
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.title'),
              text: this.$t(`products.insert.accordionList.alert.${this.type}Updated`),
              time: this.$u.getReadingTime(this.$t(`products.insert.accordionList.alert.${this.type}Updated`)),
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            })
            this.$emit('item:changed')
          }).catch(() => {
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.errorTitle'),
              text: this.$t('products.insert.accordionList.alert.error'),
              time: this.$u.getReadingTime(this.$t('products.insert.accordionList.alert.error')),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          })
        }
        this.status = 'insert'
        break

      case 'delete':
        if (this.type === 'category') {
          deleteCategory(this.parentData).then(() => {
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.title'),
              text: this.$t(`products.insert.accordionList.alert.${this.type}Deleted`),
              time: this.$u.getReadingTime(this.$t(`products.insert.accordionList.alert.${this.type}Deleted`)),
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            })
            this.$emit('item:changed')
          }).catch(() => {
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.errorTitle'),
              text: this.$t('products.insert.accordionList.alert.error'),
              time: this.$u.getReadingTime(this.$t('products.insert.accordionList.alert.error')),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          })
        } else if (this.type === 'brand') {
          deleteBrand(this.parentData).then(() => {
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.title'),
              text: this.$t(`products.insert.accordionList.alert.${this.type}Deleted`),
              time: this.$u.getReadingTime(this.$t(`products.insert.accordionList.alert.${this.type}Deleted`)),
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            })
            this.$emit('item:changed')
          }).catch(() => {
            this.$vs.notify({
              title: this.$t('products.insert.accordionList.alert.errorTitle'),
              text: this.$t('products.insert.accordionList.alert.error'),
              time: this.$u.getReadingTime(this.$t('products.insert.accordionList.alert.error')),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              color: 'danger'
            })
          })
        }
        this.status = 'insert'
        break
      }
    },
    deleteItemAction () {
      this.status = 'delete'
      this.deleteParentDialogStatus = true
    },
    setItemToEdit () {
      this.status = 'edit'
      this.item = this.parentData
      this.editParentDialogStatus = true
    },
    cancelAction () {
      this.status = 'insert'
      this.item = {
        id: 'new',
        name: '',
        parent_id: this.parentData.id,
        attributes: []
      }
    }
  },
  watch: {
    selectedData: {
      handler () {
        this.$emit('input', this.selectedData)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-list-item-children {
  padding-left: 20px;

  .accordion-list-item-parent-box {

    .vs-divider {
      padding: 0;
      margin: 0 10px;
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
      width: auto !important;

      .vs-divider-border {
        border-color: #10163a !important;
      }
    }

    .category-selector {
      width: 15px;
      height: 15px;
      background: none;
      border: 2px solid #131314;
      border-radius: 3px;
      margin: auto;
    }

    .accordion-list-item-parent {
      display: flex;
      background: none;
      border: none;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      white-space: nowrap;

      span {
        display: inline-block;
        height: 25px;
      }

      .collapse-icon {
        padding: 0 5px;
        display: inline-block;
        line-height: 30px;
        color: #131314;

        svg {
          font-size: 14px;
        }
      }

      .lock-icon {
        padding: 0 5px;
        display: inline-block;
        line-height: 21px;

        svg {
          font-size: 10px;
        }
      }
    }

    .actions-button {
      width: 25px;
      height: 25px;
      border: none;
      border-radius: 10px;
      margin: 2px;
      color: #ffffff;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.disabled {
        opacity: 0.65;
      }
    }
  }
}
</style>
