<template>
  <div class="u-input custom-email-number-input">
    <label class="u-input-box" :class="[{'is-not-valid': !email.isValid || inValid, 'disabled': disabled}, classes || {}]" :style="getStyles">
<!--      <span class="input-label" :class="{'is-focus': email.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>-->

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="u-input-label text-subtitle" :class="[{'is-focus': email.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <input type="text"
             dir="ltr"
             :class="[{'disabled': disabled}]"
             @input="handleInputValue"
             @contextmenu="handleContextMenuClicked($event)"
             @keydown.enter="handleKeydownEnter"
             :autocomplete="false"
             :name="Math.random()"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             v-model="email.value">
    </label>
  </div>
</template>

<script>
export default {
  name: 'customEmailInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelPlaceholder: {
      type: String,
      default: ''
    },
    labelPosition: {
      type: String,
      default: 'outline',
      validator (value) {
        return ['outline', 'outside'].includes(value)
      }
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    inValid: {
      type: Boolean,
      default: () => { return false }
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    classes: {
      type: [Object, Array, String],
      default () { return {} }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      email: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.email.isValid = true
    }
  },
  methods: {
    handleInputValue () {
      this.validateValue()
      this.$emit('input', {value: this.email.value, isValid: this.email.isValid})
    },
    validateValue () {
      if (this.isEmpty && this.email.value.length === 0) {
        this.email.isValid = true
      } else {
        const re = this.$u.validator('regex.customEmail')
        this.email.isValid = re.test(this.email.value)
      }
    },
    initValues () {
      this.email.value = this.value.value
      this.validateValue()
    },
    handleContextMenuClicked (event) {
      const payload = {
        actions: {
          clipboard: this.email.value,
          paste: true
        },
        event
      }
      this.$store.dispatch('helper/contextMenuClicked', payload)
    },
    handleKeydownEnter () {
      this.$emit('pressEnter')
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-email-number-input {
  position: relative;
  margin: 15px 0 25px;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border-radius: .5rem;
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none !important;

      &.disabled {
        opacity: 0.75;
      }
    }
  }
}
</style>
