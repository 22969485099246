<template>
  <div class="u-input custom-validate-input">
    <label class="u-input-box" :class="[{'is-not-valid': !data.isValid || inValid, 'disabled': disabled}, classes || {}]" :style="getStyles">
<!--      <span class="input-label"
            :class="{'is-focus': data.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>-->

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="u-input-label text-subtitle" :class="[{'is-focus': data.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <textarea
        ref="textarea"
        :class="[align === 'right' ? 'text-left' : 'text-right', {'disabled': disabled}, {'rtl': !rtl}, {'ltr': rtl}]"
        :style="inputStyles"
        rows="1"
        @input="handleInputValue, $emit('input', data)"
        @click="$emit('click')"
        @contextmenu="handleContextMenuClicked($event)"
        @keydown.enter="handleKeydownEnter"
        :autocomplete="false"
        :name="Math.random()"
        :placeholder="placeholder || ''"
        :disabled="disabled"
        v-model="data.value"/>
    </label>
  </div>
</template>

<script>
export default {
  name: 'customValidateTextarea',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    rtl: {
      type: Boolean,
      default: () => { return true }
    },
    disabled: {
      type: Boolean,
      default: () => { return null }
    },
    label: {
      type: String,
      default: () => { return '' }
    },
    placeholder: {
      type: String,
      default: () => { return '' }
    },
    labelPlaceholder: {
      type: String,
      default: () => { return '' }
    },
    labelPosition: {
      type: String,
      default: 'outline',
      validator (value) {
        return ['outline', 'outside'].includes(value)
      }
    },
    align: {
      type: String,
      default: () => { return 'right' },
      validator (value) {
        return ['right', 'left', 'center'].includes(value)
      }
    },
    isEmpty: {
      type: Boolean,
      default: () => { return null }
    },
    inValid: {
      type: Boolean,
      default: () => { return false }
    },
    regex: {
      type: RegExp,
      default: () => { return null }
    },
    minLength: {
      type: Number,
      default: () => { return 0 },
      validator (value) {
        return parseInt(value) >= 0
      }
    },
    value: {
      type: Object,
      default () { return {} }
    },
    classes: {
      type: [Object, Array, String],
      default () { return {} }
    },
    styles: {
      type: Object,
      default () { return {} }
    },
    inputStyles: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      data: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.data.isValid = true
    } else {
      this.validateValue()
    }
  },
  methods: {
    handleInputValue () {
      this.data.value = this.data.value.toString().replace(this.regex, '')
      this.validateValue()
      this.$emit('input', {value: this.data.value.toString().replaceAll(',', ''), isValid: this.data.isValid})
    },
    validateValue () {
      if (this.regex !== null && this.data.value.length > 0) {
        this.data.isValid = this.regex.test(this.data.value)
      } else if (this.isEmpty) {
        this.data.isValid = true
      } else {
        this.data.isValid = this.data.value.toString().length > this.minLength
      }
    },
    initValues () {
      this.data.value = this.value.value || ''
      this.validateValue()
    },
    handleContextMenuClicked (event) {
      const payload = {
        actions: {
          clipboard: this.data.value,
          paste: true
        },
        event
      }
      this.$store.dispatch('helper/contextMenuClicked', payload)
    },
    handleKeydownEnter (event) {
      if (!event.shiftKey) {
        this.$emit('pressEnter')
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-validate-input {
  position: relative;
  margin: 15px 0 25px;

  label {
    display: block;
    padding: 6px 5px;
    border-radius: .5rem;
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      textarea {
        color: #b00000;
      }
    }

    span {
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
    }

    textarea {
      width: 100% !important;
      height: 150px;
      resize: none;
      background: transparent;
      border: none;
      outline: none !important;
      text-align: right;

      &.disabled {
        opacity: 0.75;
      }

      &::-webkit-scrollbar {
        display: block;
        background: rgba(206, 206, 206, 0.50);
        width: 10px;
        border-radius: .25rem;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        background: rgba(206, 206, 206, 1);
        border-radius: .25rem;
      }
    }
  }
}
</style>
