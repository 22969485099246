<template>
  <div class="profile-skeleton"
       :class="[{'is-mobile': isMobile}]">
    <div class="profile-box">
      <div class="profile-name"></div>
      <div class="profile-avatar"></div>
      <div class="important-data-list">
        <template v-for="key in 3">
          <div :key="key" class="important-data-item">
            <div class="key"></div>
            <div class="value"></div>
          </div>
        </template>
      </div>
      <div class="important-actions-box">
        <div class="important-actions-list">
          <template v-for="key in 3">
            <div :key="key" class="important-type-action">
              <div class="action-icon"></div>
              <div class="action-label"></div>
            </div>
          </template>
        </div>
      </div>
      <div class="actions-list-box">
        <ul class="actions-list">
          <template v-for="key in 5">
            <div :key="key" class="profile-action">
              <div class="action-icon"></div>
              <div class="action-label"></div>
            </div>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'profileSkeleton',
  props: {
    avatarSize: {
      type: String,
      default: 'big',
      validator (value) {
        return ['small', 'medium', 'big'].includes(value)
      }
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    })
  }
}
</script>

<style lang="scss" scoped>
.profile-skeleton {
  --border-radius: 0.5rem;

  .profile-box {
    padding-top: 20px;
    .profile-avatar {
      background-color: var(--bg);
      aspect-ratio: 1 / 1;
      border-radius: 25px;
      max-width: 225px;
      height: 225px;
      margin: auto;
    }

    .profile-name {
      max-width: 225px;
      margin: auto;
      height: calc(var(--l-h) + 5px);
      border-radius: var(--border-radius);
      background-color: var(--bg);
    }

    .important-actions-box {
      .important-actions-list {
        gap: 10px;

        .important-type-action {
          border-radius: var(--border-radius);
          padding: 10px 5px;
          background-color: var(--c-bg);

          .action-icon {
            height: 20px;
            width: 20px;
            border-radius: var(--border-radius);
            background-color: var(--bg);
          }

          .action-label {
            width: 90%;
            height: calc(var(--l-h) - 5px);
            margin-top: 10px;
            background-color: var(--bg);
            border-radius: var(--border-radius);
          }
        }
      }
    }
    .important-data-list {
      .important-data-item {
        flex: 1;

        .value, .key {
          width: 100%;
          height: calc(var(--l-h) - 5px);
          margin-top: 10px;
          background-color: var(--bg);
          border-radius: var(--border-radius);
        }

        .key {
          width: 80%;
        }
      }
    }

    .actions-list-box {
      .actions-list {
        .profile-action {
          display: flex;
          align-items: center;
          margin: 0 5px;
          min-height: 50px;

          .action-icon {
            height: 25px;
            width: 25px;
            margin-right: 10px;
            border-radius: 50%;
            background-color: var(--bg);
          }

          .action-label {
            flex: 1;
            height: calc(var(--l-h));
            background-color: var(--bg);
            border-radius: var(--border-radius);
          }
        }
      }
    }
  }

  &.is-mobile {
    .profile-box {
      .profile-avatar {
        width: 50%;
        height: auto;

        &.size-small {
          width: 33%;
          height: auto;
        }

        &.size-medium {
          width: 50%;
          height: auto;
        }

        &.size-big {
          width: 80%;
          height: auto;
        }
      }
    }
  }
}
</style>
