<template>
  <div class="icon-component" :style="[{height: height + '!important'},{width: width + '!important'}]">
    <div class="icon" :class="{'icon-hover': cursorPointer}"
         :style="[{'-webkit-mask-image': 'url(' + imagePath(icon, iconType)+')'},
               {'mask-image': 'url(' + imagePath(icon, iconType)+')'},{width: width},
               {height: height},{backgroundColor: colorCode || colors[color] || colors.primary}]"></div>
  </div>
</template>

<script>
export default {
  name: 'customIcon',
  props: {
    width: {
      type: String,
      default: '25px'
    },
    height: {
      type: String,
      default: '25px'
    },
    icon: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'svg',
      validator (value) {
        return ['svg', 'png', 'jpg', 'jpeg', 'webp'].includes(value)
      }
    },
    color: {
      type: String,
      default: () => {
        return 'primary'
      },
      validator (value) {
        return ['success', 'danger', 'warning', 'gray', 'dark-gray', 'primary', 'white'].includes(value || 'success')
      }
    },
    colorCode: {
      type: String,
      default: () => {
        return ''
      }
    },
    cursorPointer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colors: {
        'primary': '#197ed5',
        'success': '#28c76f',
        'warning': '#ffa146',
        'danger': '#ea5455',
        'gray': '#cecece',
        'dark-gray': '#626262',
        'white': '#ffffff'
      }
    }
  },
  methods: {
    imagePath (name, type) {
      return require(`@/assets/icons/${  name  }.${  type}`)
    }
  }
}
</script>

<style lang="scss" scoped>

div.icon-component {
  position: relative;

  .icon {
    display: inline-block;
    background-size: cover;
    mask-position: center !important;
    -webkit-mask-position: center !important;
    mask-repeat: no-repeat !important;
    margin: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.icon-hover {

      &:hover, &:focus {
        cursor: pointer;
        filter: invert(27%) sepia(5%) hue-rotate(10deg) brightness(100%) contrast(90%);
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  @media print {
    .icon-component {
      .icon {
        background-color: unset !important;
      }
    }
  }
}

</style>
