<template>
  <div
    ref="skeleton"
    class="skeleton"
    :class="[`skeleton-${type}`]"
    :style="style"
  >
    <div v-if="type === 'circle'" class="circle-skeleton"></div>
    <profile-skeleton v-if="type === 'profile'" />
    <tree-skeleton v-else-if="type === 'tree'" :lines="lines" />
    <card-tree-skeleton v-else-if="type === 'card-tree'" :lines="lines" />
    <chart-aera-skeleton v-else-if="type === 'chart-area'" />
    <chart-column-skeleton v-else-if="type === 'chart-column'" :cols="lines" />
    <treasury-doc-skeleton v-else-if="type === 'treasury-doc'" />
    <address-card-skeleton v-else-if="type === 'address-card'" />
    <controller-card-skeleton v-else-if="type === 'controller-card'" />
    <active-session-card-skeleton v-else-if="type === 'active-session-card'" />
    <chat-card-skeleton v-else-if="type === 'chat-card'" />
    <product-barcode-skeleton v-else-if="type === 'product-barcode'" />
    <table-skeleton v-else-if="type === 'table'" />
    <shortcut-skeleton v-else-if="type === 'shortcut'" />
  </div>
</template>

<script>
import ProfileSkeleton from '@/components/customSkeleton/profileSkeleton.vue'
import TreeSkeleton from '@/components/customSkeleton/treeSkeleton.vue'
import CardTreeSkeleton from '@/components/customSkeleton/cardTreeSkeleton.vue'
import ChartAeraSkeleton from '@/components/customSkeleton/chartAeraSkeleton.vue'
import TreasuryDocSkeleton from '@/components/customSkeleton/treasuryDocSkeleton.vue'
import AddressCardSkeleton from '@/components/customSkeleton/addressCardSkeleton.vue'
import ControllerCardSkeleton from '@/components/customSkeleton/controllerCardSkeleton.vue'
import TableSkeleton from '@/components/customSkeleton/tableSkeleton.vue'
import ActiveSessionCardSkeleton from '@/components/customSkeleton/activeSessionCardSkeleton.vue'
import ChartColumnSkeleton from '@/components/customSkeleton/chartColumnSkeleton.vue'
import ProductBarcodeSkeleton from '@/components/customSkeleton/productBarcodeSkeleton.vue'
import ShortcutSkeleton from '@/components/customSkeleton/shortcutSkeleton.vue'
import ChatCardSkeleton from '@/components/customSkeleton/chatCardSkeleton.vue'

export default {
  name: 'customSkeleton',
  components: {
    ChatCardSkeleton,
    ShortcutSkeleton,
    ProductBarcodeSkeleton,
    ChartColumnSkeleton,
    ActiveSessionCardSkeleton,
    TableSkeleton,
    ControllerCardSkeleton,
    AddressCardSkeleton,
    TreasuryDocSkeleton,
    ChartAeraSkeleton,
    CardTreeSkeleton,
    TreeSkeleton,
    ProfileSkeleton
  },
  props: {
    type: {
      type: String,
      required: true,
      default: () => {
        return ''
      },
      validator (value) {
        return [
          'profile',
          'tree',
          'card-tree',
          'chart-area',
          'chart-column',
          'treasury-doc',
          'address-card',
          'controller-card',
          'active-session-card',
          'chat-card',
          'product-barcode',
          'table',
          'shortcut',
          'line',
          'rect',
          'circle',
          'circle-line',
          'circle-multi-line',
          'circle-multi-line adapt-to-circle',
          'chart-line',
          'card-1',
          'card-2',
          'card-3',
          'list',
          'youtube',
          'image'
        ].includes(value)
      }
    },
    defaultStyle: {
      type: Object,
      default: () => {
        return {}
      }
    },
    lines: {
      type: Number,
      default: 3
    },
    width: {
      type: String,
      default: () => {
        return 'full'
      }
    }
  },
  data () {
    return {
      parentWidth: 0
    }
  },
  computed: {
    style () {
      const style = []
      if (this.width === 'full') {
        this.defaultStyle['--c-w'] = `${this.parentWidth}px`
      } else {
        this.defaultStyle['--c-w'] = `${this.width}`
      }
      const styleKeys = Object.keys(this.defaultStyle)
      if (styleKeys.length) {
        styleKeys.forEach((key) => {
          if (this.defaultStyle[key]) {
            style.push(`${key}: ${this.defaultStyle[key]};`)
          }
        })
      }
      return style.join(' ')
    }
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = `${this.$parent.$el.clientWidth}`
    }, 350)
  }
}
</script>

<style scoped>

</style>
