<template>
  <date-picker :element="elementId"
               ref="datePicker"
               color="#222222"
               append-to="body"
               :range="range"
               :format="format"
               :auto-submit="autoSubmit"
               :type="type"
               :locale="getLocale($store.state.setting.clubInfo.club_locale)"
               :locale-config="localeConfig"
               :value="date"
               :highlight="highlight"
               @input="$emit('input', $event)"
               style="color: #3c3c3c !important;">

    <!-- slot for "submit-btn" -->
    <template #submit-btn="{ vm, canSubmit, color, submit, lang }">
      <button
        type="button"
        :disabled="!canSubmit"
        :style="{ color }"
        class="datepicker-submit-button"
        :min="min"
        :max="max"
        @click="submit"
        v-text="lang.submit"
      />
    </template>

    <!-- slot for "cancel-btn" -->
    <template #cancel-btn="{ vm, color, lang }">
      <button
        type="button"
        :style="{ color }"
        class="datepicker-cancel-button"
        @click="vm.visible = false"
        v-text="lang.cancel"
      />
    </template>

    <!-- slot for "now-btn" -->
    <template #now-btn="{ vm, color, goToday, lang }">
      <button
        type="button"
        :style="{ color }"
        class="datepicker-now-button"
        @click="goToday"
        v-text="lang.now"
      />
    </template>
  </date-picker>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import TimezoneService from '../../assets/js/timezones'
import moment from 'moment-jalaali'
export default {
  name: 'customDatepicker',
  mixins: [TimezoneService],
  model: {
    prop: 'date',
    event: 'input'
  },
  components: {
    datePicker: VuePersianDatetimePicker
  },
  props: {
    date: {
      type: [String, Array],
      default: ''
    },
    range: {
      type: Boolean,
      default: false
    },
    autoSubmit: {
      type: Boolean,
      default: () => { return false }
    },
    type: {
      type: String,
      default: () => { return 'date' },
      validator (value) {
        return ['date', 'time', 'datetime'].includes(value)
      }
    },
    format: {
      type: String,
      default: ''
    },
    elementId: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      localeConfig: {
        fa: {
          dir: 'rtl',
          displayFormat: this.$u.locale.validator('moment.date'),
          lang: {
            label: 'FA',
            submit: this.$t('datePicker.labels.submit'),
            cancel: this.$t('datePicker.labels.cancel'),
            now: this.$t('datePicker.labels.now'),
            nextMonth: this.$t('datePicker.labels.nextMonth'),
            prevMonth: this.$t('datePicker.labels.prevMonth')
          }
        },
        ar: {
          dow: 0,
          dir: 'rtl',
          displayFormat: vm => {
            // vm.type = date | time | datetime | year | month | yearmonth
            switch (vm.type) {
            case 'date':
              return this.$u.locale.validator('moment.date')
            case 'datetime':
              return this.$u.locale.validator('moment.dateTime')
            case 'year':
              return this.$u.locale.validator('moment.year')
            case 'month':
              return this.$u.locale.validator('moment.month')
            case 'yearmonth':
              return this.$u.locale.validator('moment.yearMonth')
            case 'time':
              return this.$u.locale.validator('moment.time')
            }
          },
          lang: {
            label: 'AR',
            submit: this.$t('datePicker.labels.submit'),
            cancel: this.$t('datePicker.labels.cancel'),
            now: this.$t('datePicker.labels.now'),
            nextMonth: this.$t('datePicker.labels.nextMonth'),
            prevMonth: this.$t('datePicker.labels.prevMonth')
          }
        }
      }
    }
  },
  methods: {
    highlight (formatted, dateMoment, checkingFor) {
      const currentTime = moment(this.$u.time()).format(this.$u.locale.validator('moment.date'))
      const attributes = {}
      if (checkingFor === 'day' && formatted === currentTime) {
        attributes['class'] = 'highlighted-now text-success'
      }
      return attributes
    }
  }
}
</script>

<style lang="scss">
.vpd-wrapper {
  .vpd-container {
    .vpd-content {
      .vpd-body {
        .highlighted-now {
          font-weight: 600;
        }

        .vpd-actions {
          display: flex !important;

          .datepicker-now-button {
            display: none;
          }

          .datepicker-submit-button,
          .datepicker-cancel-button {
            flex: 0.5;
          }
        }
      }
    }
  }
}
</style>
